export default {
  ADD_ITEM(state, item) {
    state.UnitDatas.unshift(item);
  },
  SET_UnitDatas(state, UnitDatas) {
    state.UnitDatas = UnitDatas;
  },
  UPDATE_UnitDatas(state, UnitDatas) {
    const UnitDataIndex = state.UnitDatas.findIndex(p => p.ID == UnitDatas.ID);
    if (UnitDataIndex != -1) {
      Object.assign(state.UnitDatas[UnitDataIndex], UnitDatas);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.UnitDatas.findIndex(p => p.ID == itemId);
    state.UnitDatas.splice(ItemIndex, 1);
  }
};

