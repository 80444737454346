
import state from "./moduleUnitManagmentState.js"
import mutations from "./moduleUnitManagmentMutations.js"
import getters from "./moduleUnitManagmentGetters.js"
import actions from './moduleUnitManagmentActions.js'
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
