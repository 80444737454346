<template>
  <div>
    <vx-card
    style="font-size: 18px;font-family:almarai ; padding:15px ;"
      ref="filterCard"
      :title="$t('Filters')"
      class="user-list-filters mb-8"
      collapse-action
    >
      <div class="vx-row">




        <div style="width: 200px;">
          <label style="font-size: 16px;font-family:almarai ;" >{{ $t("UnitModel") }}</label>
          <v-select
            label="name"

            name="Name"

            v-model="search.unitModelId"
            :reduce="(unitModel) => unitModel.id"
            :options="unitModelOptions"
            @input="getBuildingNumbersByModelId(search.unitModelId)"
          />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div style="width: 200px;">
          <label style="font-size: 16px;font-family:almarai ;">{{ $t("buildingnumber") }}</label>
              <v-select
                v-model="search.BuildingId"
                label="buildingName"
                class="w-full"
                :reduce="(x) => x.id"
                :options="buildingNumberOptions"
                name="buildingName"
                v-validate="'required'"
                @input="getLevelsByBuldingId(search.BuildingId)"

              />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div style="width: 200px;">
          <label style="font-size: 16px;font-family:almarai ;">{{ $t("Round") }}</label>
              <v-select
                v-model="search.LevelId"
                label="levelName"
                class="w-full"
                :reduce="(x) => x.id"
                :options="LevelsOptions"
                name="levelName"
                v-validate="'required'"

              />
        </div>
        <!-- Search Button -->
        <br />
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <vs-button
          style="font-size: 16px;font-family:almarai;margin:35px ;"

            color="rgb(255 187 85)"
            @click="handleSearch"
            >{{ $t("Search") }}</vs-button
          >
        </div>
      </div>
    </vx-card>

    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        id="Scroll"
        pagination
        :max-items="itemsPerPage"
        search
        :data="unitDataList"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <div class="vx-row">
            <vs-button
            style="font-size: 16px;font-family:almarai ;color: green;"
              type="border"

              icon-pack="feather"
              icon="icon-plus"
              @click="AddOrEditData"
              >{{ $t("AddNew") }}</vs-button
            >
          </div>

          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                  unitDataList.length - currentPage * itemsPerPage > 0
                    ? currentPage * itemsPerPage
                    : unitDataList.length
                }}
                of {{ unitDataList.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="UnitModel">{{ $t("UnitModel") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{ $t("buildingnumber") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Status">{{ $t("Round") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Status">{{ $t("UnitNumber") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Status">{{ $t("UnitType") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="FinancialPosition">{{ $t("FinancialPosition") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="ConstructionUnitStatus">{{ $t("ConstructionUnitStatus") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="DeliveryStatus">{{ $t("DeliveryStatus") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color:crimson;"> {{ $t("Action") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              @click="editData(tr)"
            >
            <vs-td>
                <p class="product-name font-medium truncate">
                  {{tr.unitModel.modelName}}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.unitBuilding.buildingName }}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{tr.unitLevel.levelName}}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{tr.unitNumber}}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{tr.unitType.name}}
                </p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium truncate">
                  {{tr.unitFinancialPositionStatus.name }}
                </p>
              </vs-td>
                <vs-td>
                <p class="product-name font-medium truncate">
                  {{tr.constructionUnitStatus.name }}
                </p>
              </vs-td>
                <vs-td>
                <p class="product-name font-medium truncate">
                  {{tr.unitDeliveryStatus.name }}
                </p>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <router-link
                  :to="{
                    name: 'UintManagmentAddOrEdit',
                    params: { ID: tr.id },
                  }"
                >
                  <feather-icon
                    icon="EditIcon"
                    @click.stop="AddOrEditData(tr)"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  />
                </router-link>

                <feather-icon
                  icon="TrashIcon"
                  svgClasses="w-10 h-5 hover:text-primary stroke-current"
                  @click.stop="openConfirm(tr)"
                />
              </vs-td>
            </tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import moduleAdsManagement from "@/store/AdsManagement/moduleAdsManagement.js";
import moduleUintModel from "@/store/UintModel/moduleUintModel.js";
import moduleUnitManagment from "@/store/UnitManagment/moduleUnitManagment.js";


import axios from "@/axios.js";


export default {
  data() {
    return {
      itemsPerPage: 10,
      rowDataForDelete: {},
      search: {

        BuildingId:null,
        LevelId:null,
        unitModelId: null,
        pageNumber: 0,
        pageSize: 20,
      },

      // ownerOptions: [],
     //unitModelOptions: [],

     buildingNumberOptions: [],
      LevelsOptions: [],
      // unitDataList: [],
      baseURL: domain,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    unitDataList() {
        debugger;
        return this.$store.state.UnitManagmentList.UnitDatas;
      },
      unitModelOptions() {
        debugger;
        return this.$store.state.moduleUintModelList.unitModelOptions;
      },
      ownerOptions() {
        debugger;
        return this.$store.state.AdsManagementList.ownerOptions;
      }

  },

  methods: {

    getBuildingNumbersByModelId(modelId) {
      debugger;
      if (!modelId) return;

      try {
        const response = axios.get("API/UnitData/GetBuildingByModelId?Id="+modelId)
        .then((res) => {
        debugger;
        this.buildingNumberOptions = res.data.data;
        console.log(this.buildingNumberOptions);
      });
        const data =  response.json();

        if (data.message === "Data list: ") {
          const buildingNumbers = data.data.map(item => item.buildingNumber);
          this.buildingNumberOptions = buildingNumbers;
        } else {
          this.buildingNumberOptions = [];
          console.log("No data found");
        }
      } catch (error) {
        console.error("Error fetching building numbers:", error);
      }
    },

    getLevelsByBuldingId(buldingId) {
      debugger;
      if (!buldingId) return;

      try {

        const response = axios.get("API/UnitData/GetlevelsByBuildingId?Id="+buldingId)
        .then((res) => {
        debugger;
        this.LevelsOptions = res.data.data;
        console.log(this.LevelsOptions);
      });
        const data =  response.json();

        if (data.message === "Data list: ") {
          const LevelsOptions = data.data.map(item => item.LevelsOptions);
          this.LevelsOptions = LevelsOptions;
        } else {
          this.LevelsOptions = [];
          console.log("No data found");
        }
      } catch (error) {
        console.error("Error fetching building numbers:", error);
      }
    },

    // fetchmUintModelOptions() {
    //   debugger;
    //   this.$store
    //     .dispatch("moduleUintModelList/fetchDataListItems")
    //     .then((res) => {
    //       debugger;
    //       this.unitModelOptions = res.data.data;
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching unit model options:", error);
    //     });
    // },
     handleSearch() {
       debugger;
       this.$store
         .dispatch("UnitManagmentList/SearchUnitData", this.search)
         .then((res) => {
           debugger;
           this.unitDataList = res.data.data;
         });
     },

    // unitownerlist() {
    //   debugger;
    //   this.$store
    //     .dispatch("AdsManagementList/SearchUnitOwner", this.search)
    //     .then((res) => {
    //       debugger;
    //       this.ownerOptions = res.data.data;
    //     });
    // },
    AddOrEditData(data) {
      debugger;
      if(data.id === undefined){
        data.id = 0;
      }
      this.$router.push(`/UnitManagement/UintManagmentAddOrEdit/${data.id}`);
    },
    openConfirm(data) {
      debugger;
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully"),
      });
    },
    deleteData(data) {
  debugger;
  this.$store.dispatch("UnitManagmentList/DeleteUnitDataObject", data)
    .then(() => {
      this.handleSearch();
    })
    .catch((err) => {
      console.error(err);
      this.$store.dispatch("UnitManagmentList/SearchUnitData");
    });
},

  },
  created() {
    if (!moduleUintModel.isRegistered) {
      this.$store.registerModule("moduleUintModelList", moduleUintModel);
      moduleUintModel.isRegistered = true;
    }

    if (!moduleUnitManagment.isRegistered) {
      this.$store.registerModule("UnitManagmentList", moduleUnitManagment);
      moduleUnitManagment.isRegistered = true;
    }

    if (!moduleAdsManagement.isRegistered) {
      this.$store.registerModule(
        "AdsManagementList",
        moduleAdsManagement
      );
      moduleAdsManagement.isRegistered = true;
    }

    this.$store
        .dispatch("AdsManagementList/SearchUnitOwner", this.search)
     this.$store
        .dispatch("UnitManagmentList/SearchUnitData", this.search)
    this.$store
        .dispatch("moduleUintModelList/fetchDataListItems")
    //  this.unitownerlist();
    // this.handleSearch(this.search);
    // this.fetchmUintModelOptions();
  },
  mounted() {
    this.isMounted = true;
    this.fetchmUintModelOptions();
  },
};
</script>

<style lang="scss">

#data-list-list-view small {
  color: rgb(255, 115, 0);
  text-decoration: underline rgb(255, 115, 0);
}

.vxCard:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0%;
  margin-left: 10%;
  z-index: 500000;
  width: 25%;
  height: 20%;
  border-top: 3px solid rgb(255, 115, 0) !important;
  border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
  border-right: 0 none rgba(255, 0, 0, 0.9) !important;
  border-left: 0 none rgba(0, 0, 0, 0.9) !important;
}
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
