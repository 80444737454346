import axios from "@/axios.js";

export default {
  addItem({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .post("API/UnitData/AddUnit", item)
        .then(response => {
          debugger;
          resolve(response);
          commit(
            "ADD_ITEM",
            Object.assign(item, { ID: response.data.data.ID })
          );
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchDataListItems({ commit }) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .get("API/UnitData/GetAllUints")
        .then(response => {
          debugger;
          resolve(response);
          commit("SET_UnitDatas", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetUnitsForDashboard() {
    debugger;

    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/UnitData/GetUnitsForDashboard")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  SearchUnitData({ commit }, search) {
    return new Promise((resolve, reject) => {
      debugger
      axios
        .post("API/UnitData/SearchUintData", search)
        .then(response => {
          debugger;
          resolve(response);
          commit("SET_UnitDatas", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateItem(context, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .put("API/UnitData/UpdateUnit", item)
        .then(response => {
          resolve(response);
         // commit("UPDATE_UnitDatas", item);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetItemByID(context, itemid) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/UnitData/GetUnitDataById?Id=" + itemid)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteUnitDataObject({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("API/UnitData/DeleteUnit?unitId=" + item.id)
        .then(response => {
          resolve(response);
          commit("REMOVE_ITEM", item.ID);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UploadImage(context, image) {
    const formData = new FormData();
    formData.append("file",image, image.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Common/UploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
